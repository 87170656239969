/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = ({ location }) => scrollToAnchor(location);

function scrollToAnchor(location, mainNavHeight = 0) {
    if (location && location.hash) {
        const item = document.querySelector(`${location.hash}`).offsetTop
        window.scrollTo({
            top: item - mainNavHeight,
            behavior: "smooth",
        })
    }
    return true
}